<script>
import { Doughnut } from 'vue-chartjs'

/**
 * Displays a doughnut with given values
 * @displayName PieChart
 */
export default {
  extends: Doughnut,
  props: {
    /**
     * Contains the data to display in the doughnut
     */
    chartData: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  watch:{
    chartData: function (){
      this.renderChart(this.chartData, this.options)
    }
  },
  data () {
    return {
      options: {
        legend: {
          display: true,
          position: 'bottom',
        },
        cutoutPercentage: 70,
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
